import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import ModalVideo from 'react-modal-video'
import {Link} from 'react-router-dom';

import axios from "axios";
import nl2br  from 'react-nl2br';



class About extends Component{

    constructor(props) {
        super(props);
        this.state = {
            global_content: [],
            our_services: [],
            group_of_companies: [],
            our_products: [],
            about1: [],
            about2: [],
            about3: [],

           
        }
    }
    componentWillMount() {
        console.log('First this called');
    }

    getData() {

        axios.get(`https://cityfort.ae/php/global.php`)
            .then(response => {
                this.setState({ global_content: response.data.global_content })
                this.setState({ our_services: response.data.our_services })
                this.setState({ group_of_companies: response.data.group_of_companies })
                this.setState({ our_products: response.data.our_products })
                this.setState({ about1: response.data.about[0] })
                this.setState({ about2: response.data.about[1] })
                this.setState({ about3: response.data.about[2] })

            })
            .catch(e => {
                console.log(e);
                this.setState({ ...this.state, isFetching: false });
            });
    }


    componentDidMount() {
        this.getData();
    }

    render(){
        return(
            <div>

                {/* Navigation bar */}
                <NavBar data={this.state.global_content}/>
                
                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>About Us</h1>
                                    <ul className="page-breadcrumb">
                                        <li><Link to="/">Home</Link></li>
                                        <li>About Us</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--top--120">
                {/*About section start*/}
                <div className="about-section section-space--inner--bottom--40">
                <div className="container">
                    <div className="row row-25 align-items-center">
                    <div className="col-lg-6 col-12 mb-30">
                        <div className="about-image-two">
                        <img src={`https://cityfort.ae/images/land1.png`} alt="" />
                          
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 mb-30">
                        <div className="about-content-two">
                        <h1>{   this.state.about1.title}</h1>
                        <p>{  nl2br(this.state.about1.description) }</p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*About section end*/}
                
                {/* Feature Icon */}

                {/*About section start*/}
                <div className="about-section section-space--inner--120">
                <div className="container">
                    <div className="about-wrapper row">
                    <div className="col-sm-6 col-12 order-1 order-lg-2">
                        <div className="about-image about-image-1">
                        <img src="https://cityfort.ae/images/land.png" alt="" />
                        </div>
                    </div>
                    <div className="col-sm-6 col-12 order-2 order-lg-3">
                        <div className="about-image about-image-2">
                            <br/> <br/>
                            <img src="https://cityfort.ae/images/land3.png" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 order-3 order-lg-1">
                        <div className="about-content about-content-1">
                        <h1>{  this.state.about2.title }</h1>
                        <p>
                        {  nl2br(this.state.about2.description) }
                        </p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 order-4">
                        <div className="about-content about-content-2">
                        <h1>{  this.state.about3.title }</h1>
                        <p>
                        {  nl2br(this.state.about3.description) }
                        
                    
                    </p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*About section end*/}
                


                {/* Brand logo */}

                </div>


                {/* Footer */}
                <Footer data={this.state.global_content}/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default About;