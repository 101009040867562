import React, { Component, useState } from 'react';
import axios from "axios";
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import {Link} from 'react-router-dom';

class Services extends Component {

    constructor(props) {
        super(props);
        this.state = {
            global_content: [],
            our_services: [],
            group_of_companies: [],
            our_products: [],
           
        }
    }
    componentWillMount() {
        console.log('First this called');
    }

    getData() {

        axios.get(`https://cityfort.ae/php/global.php`)
            .then(response => {
                this.setState({ global_content: response.data.global_content })
                this.setState({ our_services: response.data.our_services })
                this.setState({ group_of_companies: response.data.group_of_companies })
                this.setState({ our_products: response.data.our_products })
            })
            .catch(e => {
                console.log(e);
                this.setState({ ...this.state, isFetching: false });
            });
    }


    componentDidMount() {
        this.getData();
    }



    render() {

        let Datalist = this.state.our_services.map((val, i) => {
            return (
                <div className="col-lg-6 col-md-6 col-12 section-space--bottom--30" key={i}>
                    <div className="service-grid-item">
                        <div className="service-grid-item__image">
                            <div className="service-grid-item__image-wrapper">
                                    <img style={{ height:300 }} src={`images/${val.image}`} className="img-fluid" alt="Service Grid" />
                            </div>
                           
                        </div>
                        <div className="service-grid-item__content">
                            <h3 className="title">
                               {val.title}
                            </h3>
                            <p className="subtitle">{val.description}</p>
                            {/* <a href={`${process.env.PUBLIC_URL}/${val.pageLink}`} className="see-more-link">SEE MORE</a> */}
                        </div>
                    </div>
                </div>
            )
        });

        return (
            <div>

                {/* Navigation bar */}
                <NavBar data={this.state.global_content} />

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>Our Services</h1>
                                    <ul className="page-breadcrumb">
                                        <li><Link to="/">Home</Link></li>
                                        <li>Services</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                {/*====================  service page content ====================*/}
                <div className="page-wrapper section-space--inner--120">
                    {/*Service section start*/}
                    <div className="service-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="service-item-wrapper">
                                        <div className="row">
                                            {Datalist}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/><br/><br/><br/>
                    {/*Service section end*/}
                </div>

                {/*====================  End of service page content  ====================*/}

                {/* Brand logo */}

                {/* Footer */}
                <Footer data={this.state.global_content} />

                {/* Mobile Menu */}
                <MobileMenu />

            </div>
        )
    }
}

export default Services;