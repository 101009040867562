import React, { Component } from 'react';
import Swiper from 'react-id-swiper';
import {Link} from 'react-router-dom';

let anchorRef = React.createRef()

class ProjectSlider extends Component{

    constructor(props) {
        super(props)
        this.goNext = this.goNext.bind(this)
        this.goPrev = this.goPrev.bind(this)
        this.swiper = null
      }
    
      goNext() {
        if (this.swiper) this.swiper.slideNext()
      }
    
      goPrev() {
        if (this.swiper) this.swiper.slidePrev()
      }
    
      
    render(){
        
        const params = {
            slidesPerView : 1,
            loop: true,
            speed: 300,
            spaceBetween: 30,
            lazy: true,
            shouldSwiperUpdate:true,
            rebuildOnUpdate:true,
        
        };
        
        let data = this.props.data;

        let DataList = data.map((val, i)=>{

            return(
                <div className="swiper-slide latest-project-slider__single-slide" key={i}>
                    <div className="row row-30 align-items-center">
                        <div className="col-lg-4">
                            <div className="image" style={{ textAlign:'center'}}>
                            <Link   to={`project-details/${val.id}`} >
                                <img style={{ width:350 }} className="img-fluid" src={`https://cityfort.ae/images/${val.main_image}`}  alt="" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="content" style={{ textAlign:'center'}}>
                                <h2 className="title">{val.title}</h2>
                                <p className="desc" style={{ paddingRight:50, paddingLeft:50, textAlign:'center'}}>{val.description}</p>
                                <Link   to={`project-details/${val.id}`} className="see-more-link see-more-link--color">VIEW MORE</Link>
                            </div>
                        </div>
                    </div>
                </div>
            )
        });

        return(
            <div>
               {/*====================  project slider area ====================*/}
                <div className="project-slider-area grey-bg section-space--inner--120">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12">
                        {/* section title */}
                        <div className="section-title-area text-center">
                            <h2 className="section-title section-space--bottom--50">Latest Projects </h2>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="latest-project-slider">
                            <div className="latest-project-slider__container-area">
                                <Swiper {...params} ref={node => { if (node) this.swiper = node.swiper }}>
                                    {DataList}
                                </Swiper>
                            
                                <button className="ht-swiper-button-prev ht-swiper-button-nav" onClick={this.goPrev}><i className="ion-ios-arrow-left" /></button>
                                <button className="ht-swiper-button-next ht-swiper-button-nav" onClick={this.goNext}><i className="ion-ios-arrow-forward" /></button>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            {/*====================  End of project slider area  ====================*/}
            </div>
        )
    }
}


export default ProjectSlider;