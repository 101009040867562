import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import PhotoGallery from './components/PhotoGallery';
import axios from "axios";
import {Link} from 'react-router-dom';
import Swiper from 'react-id-swiper';


class Projects extends Component{
   
    constructor(props) {
        super(props);
        this.goNext = this.goNext.bind(this)
        this.goPrev = this.goPrev.bind(this)
        this.swiper = null
        this.state = {
            global_content: [],
            our_services: [],
            group_of_companies: [],
            our_products: [],
            selected_project: [],
            project_images:[],
            
           
        }
    }


      goNext() {
        if (this.swiper) this.swiper.slideNext()
      }
    
      goPrev() {
        if (this.swiper) this.swiper.slidePrev()
      }
    

    componentWillMount() {
        console.log('First this called');
    }

    getData() {
        var projectID = this.props.match.params.id;


        axios.get(`https://cityfort.ae/php/global.php`)
            .then(response => {
                this.setState({ global_content: response.data.global_content })
                this.setState({ our_services: response.data.our_services })
                this.setState({ group_of_companies: response.data.group_of_companies })
                this.setState({ our_products: response.data.our_products })

                for(var i=0;i< response.data.our_products.length; i++){
                    
                    if( response.data.our_products[i].id == projectID){
                        this.setState({ selected_project: response.data.our_products[i] })
                        this.setState({ project_images: response.data.our_products[i].image.split(',') })

                    }

                }
                
               
            })
            .catch(e => {
                console.log(e);
                this.setState({ ...this.state, isFetching: false });
            });







    }


    componentDidMount() {
        this.getData();
    }

 
    render(){

        const params = {
            slidesPerView : 1,
            loop: true,
            speed: 300,
            spaceBetween: 30,
            lazy: true,
            shouldSwiperUpdate:true,
            rebuildOnUpdate:true,
            nav:true
        
        };
     
        let ProjectImages = this.state.project_images.map((val, i)=>{

            return(
                <div className="swiper-slide latest-project-slider__single-slide" key={i}>
                    <div className="row row-30 align-items-center">
                        <div className="col-lg-12">
                            <div className="image" style={{ textAlign:'center'}}>
                         
                                <img style={{ maxWidth:800 }}  className="img-fluid" src={`https://cityfort.ae/images/${val}`} />
                              
                            </div>
                        </div>
                       
                    </div>
                </div>
            )
        });

        
        return(
            <div>
                {/* Navigation bar */}

              
                <NavBar  data={this.state.global_content} />

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>{ this.state.selected_project.title }</h1>
                                    <ul className="page-breadcrumb">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/projects">Projects</Link></li>
                                        <li>{ this.state.selected_project.title }</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                {/*====================  project details page content ====================*/}
                <div className="page-wrapper section-space--inner--120">
                {/*Projects section start*/}
                <div className="project-section">
                    <div className="container">
                    <div className="row">
                        <div className="col-12 section-space--bottom--40">
                        <div className="project-image" style={{ textAlign : 'center' }}>
                          
                        <Swiper {...params} ref={node => { if (node) this.swiper = node.swiper }}>
                                    {ProjectImages}
                                </Swiper>
                         
                                <button className="ht-swiper-button-prev ht-swiper-button-nav" onClick={this.goPrev}><i className="ion-ios-arrow-left" /></button>
                                <button className="ht-swiper-button-next ht-swiper-button-nav" onClick={this.goNext}><i className="ion-ios-arrow-forward" /></button>
                         
                         </div>
                        </div>
                       
                        <div className="col-lg-12 col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15">
                            <div className="project-details">
                                <h2>{ this.state.selected_project.title }</h2>
                                <p>{ this.state.selected_project.description }</p>
                            </div>
                        </div>
                        <div className="col-12">
                            <PhotoGallery/>
                        </div>
                    </div>
                    </div>
                </div>
                {/*Projects section end*/}

                </div>

                {/*====================  End of project details page content  ====================*/}

                {/* Brand logo */}

                {/* Footer */}
                <Footer  data={this.state.global_content} />

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default Projects;