import React, { Component } from 'react';
import NavBar from './components/NavBar';
import HeroSliderOne from './components/HeroSliderOne';
import ServiceGridSlider from './components/ServiceGridSlider';
import VideoCta from './components/VideoCta';
import ProjectSlider from './components/ProjectSlider';
import Footer from './components/Footer';
import axios from "axios";


class HomeOne extends Component {

    constructor(props) {
        super(props);
        this.state = {
            global_content: [],
            our_services: [],
            group_of_companies: [],
            our_products: [],
           
        }
    }
    componentWillMount() {
        console.log('First this called');
    }

    getData() {

        axios.get(`https://cityfort.ae/php/global.php`)
            .then(response => {
                this.setState({ global_content: response.data.global_content })
                this.setState({ our_services: response.data.our_services })
                this.setState({ group_of_companies: response.data.group_of_companies })
                this.setState({ our_products: response.data.our_products })
            })
            .catch(e => {
                console.log(e);
                this.setState({ ...this.state, isFetching: false });
            });
    }


    componentDidMount() {
        this.getData();
    }


    render() {

        return (
            <div>

                <NavBar data={this.state.global_content} />
                <HeroSliderOne data={this.state.global_content} />
                <VideoCta data={this.state.global_content} />
                <ServiceGridSlider data={this.state.our_services} />
                <ProjectSlider data={this.state.our_products} />
                <Footer data={this.state.global_content} />

            </div>
        )
    }
}


export default HomeOne;