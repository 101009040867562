import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import BlogPostGrid from './components/BlogPostGrid';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import ServiceTabTwo from './../components/ServiceTabTwo';

import axios from "axios";



class groupOfCompanies extends Component{

    constructor(props) {
        super(props);
        this.state = {
            global_content: [],
            our_services: [],
            group_of_companies: [],
            our_products: [],
           
        }
    }
    componentWillMount() {
        console.log('First this called');
    }

    getData() {

        axios.get(`https://cityfort.ae/php/global.php`)
            .then(response => {
                this.setState({ global_content: response.data.global_content })
                this.setState({ our_services: response.data.our_services })
                this.setState({ group_of_companies: response.data.group_of_companies })
                this.setState({ our_products: response.data.our_products })
            })
            .catch(e => {
                console.log(e);
                this.setState({ ...this.state, isFetching: false });
            });
    }


    componentDidMount() {
        this.getData();
    }



    render(){

        return(
            <div>

                {/* Navigation bar */}
                <NavBar data={this.state.global_content}/>

              

                

                <ServiceTabTwo data={this.state.group_of_companies}/>






                <Footer data={this.state.global_content}/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default groupOfCompanies;