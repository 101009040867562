import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class ServiceTabTwo extends Component{
    constructor() {
        super();
        this.state = { tabIndex: 0 };
      }
    render(){
        
        // /* service tab menu */
        // let serviceTabMenuData = [
        //     {iconName: 'flaticon-002-welding', tabMenuName: 'Land Mining'},
        //     {iconName: 'flaticon-004-walkie-talkie', tabMenuName: 'Work Management'},
        //     {iconName: 'flaticon-015-cart', tabMenuName: 'Material Engineering'},
        //     {iconName: 'flaticon-010-tank-1', tabMenuName: 'Power and Energy'}
        // ];


       let serviceTabMenuData = this.props.data;

        let serviceTabMenuDatalist = serviceTabMenuData.map((val, i)=>{
            return(
                <Tab key={i}>  <span className="text">{val.title}</span></Tab>
            )
        });

        
        /* service tab content */
        
        let serviceTabContentData = this.props.data;
    

        let serviceTabContentDatalist = serviceTabContentData.map((val, i)=>{
            return(
                <TabPanel key={i}>
                    <div className="service-tab__single-content-wrapper" style={{ justifyContent: "left", padding: 15 }}>
                    <div style={{ textAlign:'center' }} >
                            <img style={{ maxWidth:200, margin:'0 auto' }} src={`http://cityfort.ae/images/${val.company_logo}`}/>
                            <br/></div >     <div >
                            <h3 >{val.title}</h3>
                            <p >{val.description}</p>
                            <a target="_blank" href={`http://${val.company_website}`} className="see-more-link">Visit Website</a>
                        </div>
                    </div>
                </TabPanel>
            )
        });

        return(
            <div>

                {/*====================  service tab area ====================*/}
                <div className="service-tab-area section-space--inner--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                {/* section title */}
                                <div className="section-title-area text-center section-space--bottom--50">
                                    <h2 className="section-title">GROUP OF COMPANIES</h2>
                                </div>

                            </div>
                            <div className="col-lg-12">
                                {/* service tab wrapper */}
                                
                                <div className="service-tab-wrapper">
                                <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ tabIndex })} >
                                    <div className="row no-gutters">
                                        <div className="col-md-4">
                                            <TabList>
                                                <div className="service-tab__link-wrapper" >
                                                    {serviceTabMenuDatalist}
                                                </div>
                                            </TabList>
                                        </div>

                                        <div className="col-md-8">
                                            {serviceTabContentDatalist}
                                        </div>
                                    </div>
                                </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of service tab area  ====================*/}
            </div>
        )
    }
}

export default ServiceTabTwo;